import React, { useState } from 'react';
import { UseAuth } from './AuthProvider';
import QuestionAdviceOverlay from './QuestionAdviceOverlay';

const QuestionForm = ({ setResponse, setIsError, setIsLoading, isLoading, handleNewQuery }) => {
  const [question, setQuestion] = useState('');
  const { token, onLogout } = UseAuth();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const response = await fetch('/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ query: question }),
      });
      
      const data = await response.json();
      if(response.status === 401) {
        onLogout();
      }
      else if(response.status >= 400) {
        setIsError(true);
        setResponse(`Error occurred while fetching the response.\n\nError Message: ${data.msg}`);
      } else {
        setIsError(false);
        setResponse(data);
        handleNewQuery(question, data); // Add new query and response to history
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
      setResponse('Error occurred while fetching the response.');
    }

    setIsLoading(false);
  };

  const handleAdiveClick = () => {
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    if(token) {
      localStorage.setItem('has_seen_about', true);
    }
  };

  return (
    <div>
    <p style={{'margin-top': '0px'}}>For best results, please review our <a onClick={handleAdiveClick} href='/#'>guidance regarding questions</a>.</p>
    <form className='question-form' onSubmit={handleSubmit}> 
      <input
        type="text"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Enter your question..."
      />
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Submit'}
      </button>
    </form>
    {isOverlayOpen && <QuestionAdviceOverlay onClose={closeOverlay} /> }
       </div>  
  );
};

export default QuestionForm;
