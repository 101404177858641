import React from 'react';
import { UseAuth } from './AuthProvider';

function Feedback({ response, onFeedbackGiven }) {
  const { token, onLogout } = UseAuth();

  async function logFeedback(feedback, question_id) {
    const response = await fetch('/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ question_id: question_id, feedback: feedback }),
    });
    
    await response.json();
    if(response.status === 401) {
      onLogout();
    }
    onFeedbackGiven();
  }
  const handleThumbsUp = async () => {
    await logFeedback(true, response.question_id);
    response.feedback = true;

  }

  const handleThumbsDown = async () => {
    await logFeedback(false, response.question_id);
    response.feedback = false;
  }

  return (
    <span className="feedback">
      { response.question_id ? (
         <span>
          <img onClick={handleThumbsUp} width="20" height="18" className={`feedback-button ${response.feedback === true ? "selected" : ""}`} src="thumbs-up.svg" alt="Mark as helpful" />    
          <img onClick={handleThumbsDown} width="20" height="18" className={`feedback-button ${response.feedback === false ? "selected" : ""}`} src="thumbs-down.svg" alt="Mark as unhelpful" />    
         </span>
      ) : (<p></p>)

      }
    </span>
  );
}

export default Feedback;