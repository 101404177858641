import React, { useState } from 'react';
import {
  Link,
  useNavigate,
  useLocation
} from 'react-router-dom';

import { UseAuth } from '../components/AuthProvider';
const Login = () => {
  const { onLogin } = UseAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await onLogin(username, password);
    setLoginStatus(response)
    if (response.access_token) {
      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    }
  }
  return (
    <div className='centered-container'>
      <form onSubmit={handleLogin} className='centered-form'>
      <div className='form-header'>
        <h1>Sign in to your account</h1>
        </div>
        <div className="field-row single">
        <div class="label-container"><label>Username</label></div>
        <div class="field-container"><input tabIndex={1}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          /></div>
        </div>
        <div className="field-row single">
        <div class="label-container"><label>Password</label><Link tabIndex={4} className='link-right' to='/forgotten-password'>Forgot your password?</Link></div>
        <div class="field-container"><input tabIndex={2}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /></div>
        </div>
        <div className='right-field'>
          <button type="submit" tabIndex={3} disabled={!username || !password}>
            Continue
          </button>
        </div>
        {loginStatus?.success === false && <p className="errorText">{loginStatus.msg}</p>}
        {loginStatus?.verification_required === true && <p>Check your email for a verification link or click <Link to='/resend-verification'>here</Link> to request a new link.</p>}
        <p>Don't have an account? <Link tabIndex={5} to='/register'>Sign up</Link></p>
      </form>
    </div>)
};

export default Login;
