import React, { useState, useEffect, useRef } from 'react';
import {
  useNavigate
} from 'react-router-dom';
import './Header.css'; 
import AboutPopupOverlay from './AboutPopupOverlay';
import { UseAuth } from './AuthProvider';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const { token, onLogout } = UseAuth();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAboutClick = () => {
    setIsOverlayOpen(true);
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutClick = () => {
    onLogout();
    setIsMenuOpen(false);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    if(token) {
      localStorage.setItem('has_seen_about', true);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    const hasSeenAbout = localStorage.getItem('has_seen_about') || false;
    if(!hasSeenAbout && token) {
      setIsOverlayOpen(true);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuOpen]);

  return (
    <div className="header">
        <div className="logo">
          <img src="/TOD_RGB_White_RGB_Logo.png" alt="Tax On Demand logo" />
        </div>
        <div className="header-right">
          <nav>
          <div className="hamburger-menu" ref={menuRef}>
            <div className={`menu-button ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            {isMenuOpen && (
              <div className="menu-options">
                <div className="option" onClick={handleHomeClick}>Home</div>
                <div className="option" onClick={handleAboutClick}>About</div>
                <div className='option'><a href="https://www.tod.tax/privacy" target="_blank" rel="noreferrer">Privacy</a></div>
                <div className='option'><a href="https://www.tod.tax/cookies-policy" target="_blank" rel="noreferrer">Cookies</a></div>
                <div className='option'><a href="https://www.tod.tax/terms" target="_blank" rel="noreferrer">Terms</a></div>
                { token ? 
                  <div className="option" onClick={handleLogoutClick}>Log Out</div> 
                  :
                  <div className="option" onClick={handleLoginClick}>Log In</div> 
                }
              </div>
            )}
          </div>
          </nav>
        </div>
        {isOverlayOpen && <AboutPopupOverlay onClose={closeOverlay} /> }
      </div>
  );
};

export default Header;
