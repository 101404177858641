import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function TypingEffect({ response }) {
  const [typedText, setTypedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(50);

  useEffect(() => {
    const typeTimer = setTimeout(() => {
      if (response && currentIndex < response.length) {
        // Append the next character to the typedText state
        setTypedText(response.slice(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);

        // Increase typing speed as more is typed
        setTypingSpeed(typingSpeed => Math.max(10, typingSpeed - 2)); // Adjust the rate of speed increase
      }
    }, typingSpeed);

    return () => {
      clearInterval(typeTimer);
    };
  }, [currentIndex, response, typingSpeed]);

  return (
    <div>
      <ReactMarkdown>{typedText}</ReactMarkdown>
    </div>
  );
}

export default TypingEffect;