import React, { useState } from 'react';
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams
} from 'react-router-dom';

import { UseAuth } from '../components/AuthProvider';
const ResetPassword = () => {
  const { onResetPassword } = UseAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [resetPasswordStatus, setResetPasswordStatus] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [searchParams] = useSearchParams();
  const pwdResetCode = searchParams.get("token");

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if(!username) {
      setResetPasswordStatus({ success: false, msg: "You must enter a username"})
    }
    if(password !== confirmPassword) {
      setResetPasswordStatus({ success: false, msg: "Password fields must match"})
    }
    const response = await onResetPassword(pwdResetCode, username, password);
    setResetPasswordStatus(response)
    if (response.access_token) {
      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    }
  }
  return (
    <div className='centered-container'>
      
      <form onSubmit={handlePasswordReset} className='centered-form'>
      <div className='form-header'>
        <h1>Set a new password</h1>
        </div>
        <div className="field-row single">
        <div class="label-container"><label>Username</label></div>
        <div class="field-container"><input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          /></div>
        </div>
        <div className="field-row single">
        <div class="label-container"><label>New password</label></div>
        <div class="field-container"><input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autocomplete="off"
          /></div>
        </div>
        <div className="field-row single">
        <div class="label-container"><label>Confirm password</label></div>
        <div class="field-container"><input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autocomplete="off"
          /></div>
        </div>
        <div className='right-field'>
          <button type="submit" disabled={!username || !password || !confirmPassword}>
            Continue
          </button>
        </div>
      </form>
      {resetPasswordStatus?.success === false && <p className='errorText'>{resetPasswordStatus.msg}</p>}
      {resetPasswordStatus?.success === true && <p>Your password has been reset, click <Link to='/login'>here</Link> to login.</p>}
    </div>)
};

export default ResetPassword;
