import React from 'react';
import ReactMarkdown from 'react-markdown';
import Feedback from './Feedback';

const History = ({ history, onFeedbackGiven }) => {
  // Reverse the history array to show latest to earliest
  const reversedHistory = history;//.slice().reverse();

  return (
    <div>
      <h2>History:</h2>
      <div className="history-list">
        {reversedHistory && reversedHistory.map((item, index) => (
          <div key={index} className="history-item">
            <ReactMarkdown className="history-query">{item.query}</ReactMarkdown>
            <Feedback response={item} onFeedbackGiven={onFeedbackGiven}/>
            <ReactMarkdown>{item.response}</ReactMarkdown>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
