import {
  Navigate,
  useLocation
} from 'react-router-dom';

import { UseAuth } from './AuthProvider';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { token, isLoadingState } = UseAuth();
  if (!token && !isLoadingState) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute