import React, { useState, useEffect } from 'react';

const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [user, setUser] = useState(null);
  const storedToken = sessionStorage.getItem('access_token');
  // setToken(storedToken);

  useEffect(() => {
    async function getSession(token) {
      setIsLoadingState(false)
      const response = await fetch(`/identity`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.status === 401) { 
        setToken(null);
        sessionStorage.removeItem('access_token');
        return;
      }
      const jsonResponse = await response.json()
      setUser(jsonResponse);
      return;
    }
    if(storedToken) {
      setToken(storedToken);
      console.log(token);
      getSession(token);
    } else {
      setIsLoadingState(false)
    }
  }, [storedToken, token]); 

  

  const handleLogin = async (username, password) => {
    const response = await fetch(`/login?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    });

    const jsonResponse = await response.json()
    if(jsonResponse.access_token) {
      setToken(jsonResponse.access_token);
    }

    sessionStorage.setItem('access_token', jsonResponse.access_token)
    return jsonResponse;
  };

  const handleVerification = async (token) => {
    const response = await fetch(`/verify?token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return await response.json();
  };

  const handleForgottenPassword = async (username) => {
    const response = await fetch(`/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username
      })
    });

    return await response.json();
  };

  const handleResetPassword = async (pwdResetCode, username, password) => {
    const response = await fetch(`/reset-password?token=${pwdResetCode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    });

    return await response.json();
  };

  const handleRegister = async (user) => {
    const response = await fetch(`/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });

    return await response.json();
  };

  const handleResendVerification = async (username) => {
    const response = await fetch(`/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username
      })
    });

    return await response.json();
  };

  const handleLogout = () => {
    setToken(null);
    sessionStorage.removeItem('access_token');
  };

  const value = {
    isLoadingState,
    token,
    user,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onVerify: handleVerification,
    onResendVerfification: handleResendVerification,
    onForgottenPassword: handleForgottenPassword,
    onResetPassword: handleResetPassword,
    onRegister: handleRegister
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const UseAuth = () => {
  return React.useContext(AuthContext);
};



export {
  AuthProvider,
  UseAuth
};
