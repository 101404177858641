import React from 'react';
import {
  useNavigate
} from 'react-router-dom';
import { RingLoader } from 'react-spinners';

const RegisterStep3 = ({ user, setUser, registerStatus, setRegisterStatus, onSubmit }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    setRegisterStatus({ ...registerStatus, msg: '', currentStep: registerStatus.currentStep -1 });
  }

  const handleContinue = () => {
    navigate('/login');
  }

  return (
    <div>
      {registerStatus.isLoading && <div className='loading-spinner'>
            <RingLoader size={30} color={'#007bff'} loading={registerStatus.isLoading} />
          </div>}
       {registerStatus?.success === false && <p className='errorText'>{registerStatus.msg}</p>}
        {registerStatus?.success === true && <div>
            <p>Your account has been created, please check your email for details on how to verfify your account.</p>
          </div>
        }
        <div className='register-step-footer'>
        <div className='left-button'>
          { registerStatus?.success === false && 
              <button type="button" onClick={() => handleBack()} >
                Back
              </button>
          }
          </div>
          <div className='right-button'>
          {registerStatus?.success === true && <button type="button" onClick={() => handleContinue()} >
            Login
          </button>}
          </div>
        </div>
      </div>)
};

export default RegisterStep3;