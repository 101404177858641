import React from 'react';

const QuestionAdviceOverlay = ({ onClose }) => {
  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('overlay')) {
      // Close the overlay when the user clicks outside the content area
      onClose();
    }
  };

  return (
    <div className="overlay" id="about-overlay" onClick={handleOverlayClick}>
      <div className="overlay-content">
        <div className='header'><h2>How to ask a great question</h2></div>
        <div className="scroll-content">
       <p>When using the ToD copilot platform to seek answers about UK tax, it's essential to frame your questions in a long-form manner, just as you would when consulting a human expert.</p>
       <p>Following the advice below will help maximise the quality of the responses you receive.</p>
       <p>
        <ul>
          <li>Phrase your question as you would if you were speaking to a human.</li>
          <li>Avoid using keyword-style queries typically employed for web searches.</li>
          <li>Include as much relevant information as possible in your your question.</li>
          <li>Try rephrasing your question with additionnal context if the copilot doesn't provide an answer.</li>
        </ul>
        </p>

        <p>One important thing to note is that Copilot doesn't currently support multi-turn questions. 
          This means that you cannot ask followup questions, doing so will result in a slightly confused state.</p>
        <p>As an example; if you ask "How much income tax would I pay if I earned £80K in a year?" and then ask another question immediately afterwards of "What about £90K?" Copilot does not understand that you are asking a follow-up to the previous question.</p>
        <p>This limitation is something we are working hard to resolve in the future.</p>
        <p>One feature that copilot does support is asking about what information it would require to provide you with an answer.</p>
        <p>For example "What information would you need to provide me with a breakdown of my corporate tax liability".</p><p>I can then use the answer to this question to form a new question regarding my tax liability with the information required such as Revenue and Deductible expenses.</p>
      </div>
      <div className='footer'><button id="close-button" onClick={onClose}>Close</button></div>
        </div>
    </div>
  );
};

export default QuestionAdviceOverlay;
