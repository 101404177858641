import React, { useState, useEffect } from 'react';
import { UseAuth } from '../components/AuthProvider';
import { useSearchParams, Link } from "react-router-dom";
const Verification = () => {
  const [verifyStatus, setVerifyStatus] = useState();
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("token");
  const { onVerify } = UseAuth();
  
  useEffect(() => {
    async function verify() {
      const response = await onVerify(verificationCode);
      setVerifyStatus(response)
    }
    verify();
  }, [onVerify, verificationCode]); 

  return (
    <div className='centered-container'>
      <div className='centered-form'>
          <div className='form-header'>
        <h1>Verify your account</h1>
        </div>
      {!verifyStatus && <p>Please wait while we check your account</p>}
      {verifyStatus?.success && <div>
        <p>Your account has been verified. </p>
        <p>Click <Link to="/login">here</Link> to login.</p>
      </div>
      }
      {verifyStatus?.success === false && (
        <div>
          <p>Account verification failed. Reason: {verifyStatus.msg}</p>
          <p><Link to='/resend-verification'>Resend Verification Link</Link></p>
        </div>
      )}
              </div>
    </div>)
};

export default Verification;