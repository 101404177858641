import React, { useState } from 'react';
import { UseAuth } from '../components/AuthProvider';
const Verification = () => {
  const [resendVerificationStatus, setResendVerificationStatus] = useState();
  const [username, setUsername] = useState('');
  const { onResendVerfification } = UseAuth();

  const handleResendVerfification = async (e) => {
    e.preventDefault();
    try {
      const response = await onResendVerfification(username);
      setResendVerificationStatus(response)
      console.log(response);
    } catch (err) {
      setResendVerificationStatus({ success: false, msg: "An error occured requesting verification link" })
    }
  }
  return (
    <div className='centered-container'>
      {resendVerificationStatus?.success && (<p>Verification link sent, please check your email.</p>)}
      {resendVerificationStatus?.success === false && (<p>Verification link request failed. Reason: {resendVerificationStatus.msg}</p>)}
      {(!resendVerificationStatus || !resendVerificationStatus.success) && (
        <form onSubmit={handleResendVerfification} className='centered-form'>
          <div className='form-header'>
        <h1>Request new verification link</h1>
        <p>Enter the email address associated with your account, and we'll send you an account verification link.</p>
        </div>
        <div className="field-row single">
          <div class="label-container"><label>Username</label></div>
          <div class="field-container"><input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username..."
          />
          </div>
          </div>
          <div className='right-field'>
            <button type="submit" disabled={!username}>Continue</button>
          </div>
        </form>
      )}
    </div>)
};

export default Verification;