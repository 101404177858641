import React, { useState } from 'react';
import {
  Link
} from 'react-router-dom';

import { UseAuth } from '../components/AuthProvider';
const ForgottenPassword = () => {
  const { onForgottenPassword } = UseAuth();
  const [forgottenPasswordStatus, setForgottenPasswordStatus] = useState();
  const [username, setUsername] = useState();

  const handleForgottenPassword = async (e) => {
    e.preventDefault();
    const response = await onForgottenPassword(username);
    setForgottenPasswordStatus(response)
  }
  return (
    <div className='centered-container'>
      
      <form onSubmit={handleForgottenPassword} className='centered-form'>
        <div className='form-header'>
        <h1>Reset your password</h1>
        <p>Enter the email address associated with your account, and we'll send you a link to reset you password.</p>
        </div>
        <div className="field-row single">
        <div class="label-container"><label>Username</label></div>
        <div class="field-container"><input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          /></div>
        </div>
        <div className='right-field'>
        <button type="submit" disabled={!username}>Continue</button>
        </div>
        <Link to='/login'>Return to login</Link>
      </form>
      {forgottenPasswordStatus?.success === false && <p className='errorText'>{forgottenPasswordStatus.msg}</p>}
      {forgottenPasswordStatus?.success === true && <p>Please check you email for your reset password link.</p>}
    </div>)
};

export default ForgottenPassword;
