import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home, Login, Verification, VerificationRequest,
  Register, ForgottenPassword, ResetPassword
} from './pages'
import Layout from './components/Layout';
import { AuthProvider } from './components/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            } />
            <Route path="login" element={<Login />} />
            <Route path="verification" element={<Verification />} />
            <Route path="resend-verification" element={<VerificationRequest />} />
            <Route path="register" element={<Register />} />
            <Route path="forgotten-password" element={<ForgottenPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>

  );
}

export default App;
