import React, { useState, useEffect, useCallback } from 'react';
import QuestionForm from '../components/QuestionForm'
import ResponseDisplay from '../components/ResponseDisplay'
import History from '../components/History'
import { UseAuth } from '../components/AuthProvider';

const Home = () => {
  const [response, setResponse] = useState('');
  const [isError, setIsError] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [history, setHistory] = useState([]);
  const { token, isLoadingState } = UseAuth();

  const getHistory = useCallback(async () => {
    const response = await fetch('/history', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    setHistory(await response.json());
  }, [token]);

  useEffect(() => {
    if(token && !isLoadingState) {
      getHistory();
    }
  }, [token, isLoadingState, getHistory]);

  // Save history to local storage when it changes
  useEffect(() => {
    localStorage.setItem('queryHistory', JSON.stringify(history));
  }, [history]);

  const handleNewQuery = async (query, response) => {
    await getHistory();
  };

  const onFeedbackGiven = async () => {
    await getHistory();
  }

  return (
    <div>
      <div className="panel">
        <QuestionForm setResponse={setResponse} setIsError={setIsError} setIsLoading={setIsLoading} isLoading={isLoading} handleNewQuery={handleNewQuery} />
      </div>
      <div className="split-container">
        <div className="form-container">
        <div className="panel">
          <ResponseDisplay response={response} isError={isError} isLoading={isLoading} onFeedbackGiven={onFeedbackGiven} />
          </div>
        </div>
        <div className="history-container">
        <div className="panel">
          <History history={history} onFeedbackGiven={onFeedbackGiven} />
          </div>
        </div>
      </div>
    </div>)
};

export default Home;
