import React, { useState } from 'react';
import RegisterStep1 from '../components/RegisterStep1'
import RegisterStep3 from '../components/RegisterStep3'

import { UseAuth } from '../components/AuthProvider';
const Register = () => {
  const { onRegister } = UseAuth();
  const [registerStatus, setRegisterStatus] = useState({ 
    isLoading: false,
    currentStep: 1,
    step1Valid: false,
    step2Valid: false
  });
  const [user, setUser] = useState({
    username: {}, password: {}, firstname: {}, lastname: {}, practiceName: {},
    partners: {}, employees: {}, accountingSoftware: [], corpTaxSoftware: [],
    jobTitle: {}, limitedCompanies: {}
  });

  const handleRegister = async (e) => {  
    setRegisterStatus({ ...registerStatus, isLoading: true, currentStep: 2 });
    const response = await onRegister({
      firstname: user.firstname.value, 
      lastname: user.lastname.value, 
      username: user.username.value, 
      password: user.password.value,
      details: {
        practiceName: user.practiceName.value,
        jobTitle: user.jobTitle.value,
        partners: user.partners.value,
        employees: user.employees.value,
        limitedCompanies: user.limitedCompanies.value,
        accountingSoftware: user.accountingSoftware.value,
        corpTaxSoftware: user.corpTaxSoftware.value
      }
    });
    setRegisterStatus({...registerStatus, ...response, isLoading: false, currentStep: 2 })
  }

  return (
    <div className='centered-container'>
      
      <form onSubmit={handleRegister} className='centered-form register'>
        <div className='form-header'>
          <h1>Create your account</h1>
        </div>
        {
          registerStatus.currentStep === 1 && <RegisterStep1 user={user} setUser={setUser} registerStatus={registerStatus} setRegisterStatus={setRegisterStatus} onSubmit={handleRegister} />
        }
        {/* {
          registerStatus.currentStep === 2 && <RegisterStep2 user={user} setUser={setUser} registerStatus={registerStatus} setRegisterStatus={setRegisterStatus} onSubmit={handleRegister} />
        } */}
        {
          registerStatus.currentStep === 2 && <RegisterStep3 user={user} setUser={setUser} registerStatus={registerStatus} setRegisterStatus={setRegisterStatus} onSubmit={handleRegister} />
        }
        </form>
          </div>)
};

export default Register;