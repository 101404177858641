import React from 'react';
import {
  Link
} from 'react-router-dom';

const RegisterStep1 = ({ user, setUser, registerStatus, setRegisterStatus, onSubmit }) => {

  function isEmpty(val){
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  const handleUsernameChange = e => {
    setUser({ ...user, username: { value: e.target.value, valid: validateEmail(e.target.value) } });
  }

  const handleFirstnameChange = e => {
    setUser({ ...user, firstname: { value: e.target.value, valid: validateRequired(e.target.value) } });
  }

  const handleLastnameChange = e => {
    setUser({ ...user, lastname: { value: e.target.value, valid: validateRequired(e.target.value) } });
  }

  const handlePasswordChange = e => {
    setUser({ ...user, password: { value: e.target.value, valid: validatePassword(e.target.value) } });
  }

  const isFormValid = user.username.valid && user.password.valid && user.firstname.valid && user.lastname.valid;

  const validateRequired = (value) => {
    if(Array.isArray(value)) {
      return value.length > 0;
    }
    else {
      return !isEmpty(value);
    }
  }

  const validatePassword = (password) => {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_@$£!%*?&#])[A-Za-z\d-_@$£!%*?&#]{10,25}$/;
    return re.test(password);
  }

  const validateEmail = (email) => {
    var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  const handleContinue = () => {
    if(isFormValid) {
      onSubmit();
    } else {
      setRegisterStatus({ ...registerStatus, showErrors: true });
    }
  }

  return (
    <div>
        <div className="field-row single">
        <div className='field-column'>
          <div class="label-container"><label>Username (email address) <span className='errorText'>*</span></label></div>
          <div class="field-container"><input
              type="text"
              value={user.username.value}
              onChange={handleUsernameChange}
            />
            { (registerStatus.showErrors && !user.username?.valid) && <p className='errorText'>Your username must be a valid email address.</p> }
            </div>
          </div>
        </div>
        <div className="field-row single">
          <div className='field-column'>
            <div className="label-container"><label>Choose a password <span className='errorText'>*</span></label></div>
            <div className="field-container"><input
                type="password"
                value={user.password.value}
                onChange={handlePasswordChange}
              />
              { (registerStatus.showErrors && !user.password?.valid) && <p className='errorText'>Passwords must be between 10 and 15 characters in length, with at least one uppercase letter, one lowercase letter, one number and one special character.</p> }
            </div>
          </div>
        </div>
        <div className="field-row">
          <div className='field-column'>
            <div className="label-container"><label>First name <span className='errorText'>*</span></label></div>
            <div className="field-container"><input
                type="text"
                value={user.firstname.value}
                onChange={handleFirstnameChange}
              />
              { (registerStatus.showErrors && !user.firstname.valid) && <p className='errorText'>First name is required.</p> }
              </div>
              
          </div>
          <div className='field-column'>
            <div className="label-container"><label>Last name <span className='errorText'>*</span></label></div>
            <div className="field-container"><input
                type="text"
                value={user.lastname.value}
                onChange={handleLastnameChange}
              />
              { (registerStatus.showErrors && !user.lastname?.valid) && <p className='errorText'>Last name is required.</p> }
              </div>
              
          </div>
        </div>
        <p>See full details of our data practices in our <a href="https://www.tod.tax/privacy" target="_blank" rel="noreferrer">Privacy Notice</a> and please email any questions to <a href="mailto:contact@tod.tax">contact@tod.tax</a>.</p>
        
        <div className='register-step-footer'>
          <div className='left-button'>
          <p>Already registered? <Link to='/login'>Login</Link></p>
          </div>
          <div className='right-button'>
            <button type="button" onClick={() => handleContinue()}>
              Continue
            </button>
          </div>
        </div>
      </div>)
};

export default RegisterStep1;