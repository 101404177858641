import React from 'react';
import { RingLoader } from 'react-spinners';
import TypingEffect from './TypingEffect';
import Feedback from './Feedback';

const ResponseDisplay = ({ response, isError, isLoading, onFeedbackGiven }) => {
  return (
    <div>
      <h2 className="response-heading">Response:</h2>
      <Feedback response={response} onFeedbackGiven={onFeedbackGiven} /> 
      <div className={`markdown-response ${isError ? "errorText" : ""}`}>
        {isLoading ? (
          <div className='loading-spinner'>
            <RingLoader size={100} color={'#007bff'} loading={isLoading} />
          </div>
        ) : (
          // isError ? (
          //   <p style="color: red;">{response}</p>
          // ) : (
            <TypingEffect response={response.response} />
          // )
        )}
      </div>

    </div>
  );
};

export default ResponseDisplay;
