import React from 'react';

const AboutPopupOverlay = ({ onClose }) => {
  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('overlay')) {
      // Close the overlay when the user clicks outside the content area
      onClose();
    }
  };

  return (
    <div className="overlay" id="about-overlay" onClick={handleOverlayClick}>
      <div className="overlay-content">
        <div className='header'><h2>About tod-CoPilot | A Generative AI tool for HMRC Manuals</h2></div>
        <div className="scroll-content">
       <p>We are excited to introduce tod-Copilot to get users' feedback and learn about its strengths and weaknesses. During the research preview, usage of tod-Copilot is free.</p> 

        <h3>User testing and feedback</h3>


        <p>We know that there are many limitations, and we plan to make regular model updates to improve in such areas. But we also hope that by providing an accessible interface to tod-Copilot, we will get valuable user feedback on issues that we are not already aware of. Users are encouraged to provide feedback on false positives/negatives and/or incorrect model outputs by emailing tod@tod.tax. </p>


        <h3>Background</h3>


        <p>The release of tod-Copilot is the latest step in Tax on Demand's mission to build tools powered by AI and automation technologies that empower accountants in general practice and enable them to better serve their clients.</p>


        <p>We are excited to carry the lessons of this release into the deployment of more capable systems, and in time to our core decision automation platform that automates the client review process to identify what tax advice your clients need, in seconds.</p>


        <h3>Limitations</h3>


        <p>tod-Copilot sometimes writes plausible-sounding but incorrect or nonsensical answers. Fixing this issue is challenging, as: (1) during RL training, there’s currently no source of truth; (2) training the model to be more cautious causes it to decline questions that it can answer correctly; and (3) supervised training misleads the model because the ideal answer depends on what the model knows, rather than what the human demonstrator knows.</p>


        <p>tod-Copilot is sensitive to tweaks to the input phrasing or attempting the same prompt multiple times. For example, given one phrasing of a question, the model can claim to not know the answer, but given a slight rephrase, can answer correctly.</p>

        <p>The model is often excessively verbose and overuses certain phrases, such as restating that. These issues arise from biases in the training data (trainers prefer longer answers that look more comprehensive) and well-known over-optimization issues.</p>

        <p>Ideally, the model would ask clarifying questions when the user provided an ambiguous query. Instead, our current model usually guess what the user intended.</p>

        <p>The service is trained on the HMRC internal manuals and some additional HMRC content. The quality and accuracy of the answers is therefore dependent on the content from the HMRC website as of the reference date (18 Sept 2023).</p>

        <h3>About tod</h3>


        <p>Tax on Demand (tod) is an artificial intelligence company. Our mission is to build tools powered by AI and automation technologies that empower accountants in general practice and enable them to better serve their clients. Since we're independently owned, we can focus on developing those emerging technologies that are likely to have a positive impact of the accountancy profession as a whole.</p>

        <p>We believe that the future is Human-AI collaboration; a world where AI systems assist and augment the capabilities of accountants in general practice to maximise opportunities, mitigate risks and eliminate significant inefficiencies in manually intensive and time-consuming tasks. We also believe that these tools should be accessible to every accountant in general practice, not just those with the time, team and resources to build them. We can imagine a world in which accountants flourish to a degree that is probably impossible for any of us to fully visualise yet. We hope our technology contributes.</p>
        </div>
        <div className='footer'><button id="close-button" onClick={onClose}>Close</button></div>
        
      </div>
    </div>
  );
};

export default AboutPopupOverlay;
